html,
body {
  margin: 0;
  min-height: 100vh;
  overflow: hidden;
  -webkit-overflow-scrolling: touch !important;
  background: #fff;
}
.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.app-public-content {
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.app-content {
  margin-top: 56px;
  margin-bottom: 56px;
  height: calc(100vh - 112px);
  overflow-y: auto;
  overflow-x: hidden;
}

.app-header {
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 100;
}

.app-footer {
  position: fixed;
  bottom: 0px;
  width: 100%;
  z-index: 100;
}
.backgroundFooter {
  background-color: #fafafa !important;
}

.app-avatar {
  width: 100px !important;
  height: 100px !important;
}

.header-title {
  flex-grow: 1;
}

.newsfeed-avatar {
  width: 50px !important;
  height: 50px !important;
}
.newsfeedAvatarBorder {
  border: 1px solid #ebedf0;
}

.file-input-img {
  width: 140px;
  height: 140px;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
  border: solid;
}

.read-only-value {
  border-radius: 5px;
  padding: 5px 10px;
  background-color: #e3e3e3;
}

.colorPrimaryOverride {
  color: #4267b2;
}
.backgroundColorPrimaryOverride {
  background-color: rgb(230, 0, 3) !important;
  color: white !important;
}
.backgroundColorPrimaryOverrideDevelop {
  background-color: #2563eb !important;
  color: white !important;
}
.MuiBottomNavigationAction-root {
  color: rgb(112, 112, 112) !important;
}
button {
  outline: none !important;
}
.widthFull {
  width: 100%;
}
.MuiPickersToolbar-toolbar,
.MuiPickersDay-daySelected {
  background-color: rgb(230, 0, 3) !important;
}
.page-title,
.MuiInput-input {
  color: rgb(112, 112, 112) !important;
}
.label-input {
  color: rgb(201, 201, 201) !important;
}
.btn-success {
  background-color: rgb(230, 0, 3) !important;
  color: white !important;
}
.btn-gray {
  background-color: rgb(112, 112, 112) !important;
  color: #fff;
}
.color-gray {
  color: rgb(112, 112, 112) !important;
}
.color-red {
  color: rgb(230, 0, 3) !important;
}
.MuiButton-contained.Mui-disabled {
  background-color: rgba(0, 0, 0, 0.12) !important;
}
.link-forgot {
  color: rgb(230, 0, 3);
}
@media (min-width: 6px) {
  .form-inline label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group {
    width: auto;
  }
  .form-inline .form-check {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}
.overrideBackgroundTab .MuiButton-containedPrimary,
.overrideBackgroundTab .MuiButton-containedPrimary:hover,
.overrideBackgroundTab .MuiButton-containedPrimary:active {
  background-color: rgb(112, 112, 112) !important;
}
.MuiButton-outlinedPrimary {
  color: rgb(112, 112, 112) !important;
  border: 1px solid rgba(112, 112, 112, 0.5) !important;
}
.swal2-styled.swal2-confirm {
  background-color: rgb(230, 0, 3);
  min-width: 6.5em;
}
.swal2-styled.swal2-cancel {
  min-width: 6.5em;
}
.swal2-styled {
  padding: 0.625em 1em;
}
.swal2-container {
  z-index: 1300 !important;
}
.MuiBottomNavigationAction-label.Mui-selected {
  font-size: 0.75rem !important;
}
.progressScreen .MuiExpansionPanelSummary-content {
  display: block;
}
.progressTitle {
  width: 100%;
  text-align: center;
  padding-bottom: 5px;
}
.progressBtn {
  width: 100%;
  text-align: center;
}
@media only screen and (min-width: 768px) {
  .progressTitle {
    width: 65%;
    float: left;
  }
  .progressBtn {
    width: 35%;
    float: left;
  }
}
.div-forgot a,
.MuiCircularProgress-colorPrimary {
  color: rgb(230, 0, 3) !important;
}
.das-btn {
  min-height: 30px !important;
  border: 1px solid gray !important;
  width: 200px;
  background-color: rgba(0, 0, 0, 0.05) !important;
}

.image-gal-item {
  height: 100px;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.image-overlay {
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: white;
  font-size: 20px;
  font-weight: bold;
}

.image-overlay-small {
  position: absolute;
  bottom: 5px;
  right: 10px;
  color: white;
  font-size: 12px;
  font-weight: bold;
}

.MuiSlider-mark {
  height: 5px;
}

.red-slider.MuiSlider-root.Mui-disabled {
  color: rgb(230, 0, 3) !important;
}

.gray-slider.MuiSlider-root.Mui-disabled {
  color: #aaa !important;
}

[class^="PrivateValueLabel-circle"] {
  background-color: white !important;
}
[class^="jss"] {
  background-color: white !important;
  color: black !important;
}

[class^="PrivateValueLabel-offset"] {
  top: -30px !important;
}
[class^="PrivateValueLabel-label"] {
  color: black !important;
}

.w-auto {
  width: auto !important;
}

.z-input input {
  z-index: 10;
}

.z-input .MuiChip-root {
  z-index: 10;
}

.rmc-picker-indicator {
  border-top: solid 3px rgb(230, 0, 3) !important;
  border-bottom: solid 3px rgb(230, 0, 3) !important;
}

.rmc-picker-item {
  color: black !important;
}

.ReactModal__Overlay {
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
}

.custom-padding-input .MuiFilledInput-input {
  padding: 10px 12px !important;
  font-size: 12px;
}

[class^="css-"][class$="-menu"] {
  z-index: 3 !important;
}

[class^=" css-"][class$="-menu"] {
  z-index: 3 !important;
}

.disabled-button {
  box-shadow: none !important;
  color: gray !important;
}
